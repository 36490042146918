<template>
    <div class="wrapper">
        <div v-if="pageNumber == 1">
            <h2>Site Details</h2>
            <div class="form-row">
                <div id="lblSiteName" class="form-label">Name of New Site:</div>
                <div class="form-input-holder">
                    <input aria-labelledby="lblSiteName" type="text" class="form-input" v-model="formData.site_name">
                </div>
            </div>
            <div class="form-row">
                <div id="lblAddress1" class="form-label">First line of address:</div>
                <div class="form-input-holder">
                    <input aria-labelledby="lblAddress1" type="text" class="form-input" v-model="formData.address_1">
                </div>
            </div>
            <div class="form-row">
                <div id="lblAddress2" class="form-label">Second line of address:</div>
                <div class="form-input-holder">
                    <input aria-labelledby="lblAddress2" type="text" class="form-input" v-model="formData.address_2">
                </div>
            </div>
            <div class="form-row">
                <div id="lblTown" class="form-label">Town:</div>
                <div class="form-input-holder">
                    <input aria-labelledby="lblTown" type="text" class="form-input" v-model="formData.town">
                </div>
            </div>
            <div class="form-row">
                <div id="lblCounty" class="form-label">County:</div>
                <div class="form-input-holder">
                    <input aria-labelledby="lblCounty" type="text" class="form-input" v-model="formData.county">
                </div>
            </div>
            <div class="form-row">
                <div id="lblPostCode" class="form-label">Post Code:</div>
                <div class="form-input-holder">
                    <input aria-labelledby="lblPostCode" type="text" class="form-input" v-model="formData.post_code">
                </div>
            </div>
            <div class="form-row">
                <div id="lblSiteReferenceNumber" class="form-label">Store Number:</div>
                <div class="form-input-holder">
                    <input aria-labelledby="lblSiteReferenceNumber" type="text" class="form-input"
                        v-model="formData.reference_number">
                </div>
            </div>
            <h3>Site survey contact details</h3>
            <div class="form-row">
                <div id="lblSiteContactName" class="form-label">Name:</div>
                <div class="form-input-holder">
                    <input aria-labelledby="lblSiteContactName" type="text" class="form-input"
                        v-model="formData.site_contact_name">
                </div>
            </div>
            <div class="form-row">
                <div id="lblSiteContactNumber" class="form-label">Number:</div>
                <div class="form-input-holder">
                    <input aria-labelledby="lblSiteContactNumber" type="text" class="form-input"
                        v-model="formData.site_contact_number">
                </div>
            </div>
            <div class="form-row">
                <div id="lblSiteContactEmail" class="form-label">Email:</div>
                <div class="form-input-holder">
                    <input aria-labelledby="lblSiteContactEmail" type="text" class="form-input"
                        v-model="formData.site_contact_email">
                </div>
            </div>
        </div>

        <div v-if="pageNumber == 2">
            <h2>Service Details</h2>

            <div class="form-row">
                <div id="lblCollectionServices" class="form-label">Do you require collection services?</div>
                <div class="form-input-number">
                    <select aria-labelledby="lblCollectionServices" class="form-input"
                        v-model="formData.collection_services">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>

            <template v-if="formData.collection_services == 'Yes'">
                <div class="form-row">
                    <div class="form-label">Days Collection of Notes required:</div>
                    <div>
                        <div class="checkbox-container" v-for="day in days">
                            <input :key="day" :id="'chkCollectionNotes' + day" class="chk" type="checkbox" :value="day"
                                v-model="formData.notes_collection_days"
                                @change="handleCheckboxChange(day, $event, 'notes_collection_days')" />
                            <label :for="'chkCollectionNotes' + day">{{ day }}</label>
                        </div>
                    </div>
                </div>

                <div class="form-row" v-if="notesCollectionRequired">
                    <div id="lblWhoProcessesNotes" class="form-label">Who processes the notes collected?</div>
                    <div class="form-input-number">
                        <select aria-labelledby="lblWhoProcessesNotes" class="form-input"
                            v-model="formData.notes_processor">
                            <option value="Loomis">Loomis</option>
                            <option value="Bank">Bank</option>
                        </select>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-label">Days Collection of Coin required:</div>
                    <div>
                        <div class="checkbox-container" v-for="day in days">
                            <input :key="day" :id="'chkCollectionCoin' + day" class="chk" type="checkbox" :value="day"
                                v-model="formData.coin_collection_days"
                                @change="handleCheckboxChange(day, $event, 'coin_collection_days')" />
                            <label :for="'chkCollectionCoin' + day">{{ day }}</label>
                        </div>
                    </div>
                </div>

                <div class="form-row" v-if="coinsCollectionRequired">
                    <div id="lblWhoProcessesCoin" class="form-label">Who processes the coin collected?</div>
                    <div class="form-input-number">
                        <select aria-labelledby="lblWhoProcessesCoin" class="form-input"
                            v-model="formData.coin_processor">
                            <option value="Loomis">Loomis</option>
                            <option value="Bank">Bank</option>
                        </select>
                    </div>
                </div>

                <div class="form-row">
                    <div id="lblFrequencyOfCollection" class="form-label">Frequency of service</div>
                    <div class="form-input-number">
                        <select aria-labelledby="lblFrequencyOfCollection" class="form-input"
                            v-model="formData.frequency_of_collection">
                            <option value="Weekly">Weekly</option>
                            <option value="Fortnightly">Fortnightly</option>
                            <option value="Every 4 weeks">Every 4 weeks</option>
                            <option value="Monthly">Monthly</option>
                        </select>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-label">Service Start Date:</div>
                    <div class="form-input-number">
                        <flat-pickr v-model="formData.service_start_date" ref="dateWrapperElement" :config="date_config"
                            class="txt full date"></flat-pickr>
                    </div>
                </div>
            </template>

            <div class="form-row">
                <div id="lblDeliveryServices" class="form-label">Do you require delivery services?</div>
                <div class="form-input-number">
                    <select aria-labelledby="lblDeliveryServices" class="form-input"
                        v-model="formData.delivery_services">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>

            <template v-if="formData.delivery_services == 'Yes'">
                <div class="form-row">
                    <div class="form-label">Days Delivery of Notes required:</div>
                    <div>
                        <div class="checkbox-container" v-for="day in days">
                            <input :key="day" :id="'chkDeliveryNotes' + day" class="chk" type="checkbox" :value="day"
                                v-model="formData.notes_delivery_days"
                                @change="handleCheckboxChange(day, $event, 'notes_delivery_days')" />
                            <label :for="'chkDeliveryNotes' + day">{{ day }}</label>
                        </div>
                    </div>
                </div>

                <template v-if="notesDeliveryRequired">
                    <div class="form-row">
                        <div id="lblWhoMakesUpNotes" class="form-label">Who makes up the notes delivered?</div>
                        <div class="form-input-number">
                            <select aria-labelledby="lblWhoProcessesNotes" class="form-input"
                                v-model="formData.notes_makes_up">
                                <option value="Loomis">Loomis</option>
                                <option value="Bank">Bank</option>
                            </select>
                        </div>
                    </div>
                </template>

                <div class="form-row">
                    <div class="form-label">Days Delivery of Coin required:</div>
                    <div>
                        <div class="checkbox-container" v-for="day in days">
                            <input :key="day" :id="'chkDeliveryCoin' + day" class="chk" type="checkbox" :value="day"
                                v-model="formData.coin_delivery_days"
                                @change="handleCheckboxChange(day, $event, 'coin_delivery_days')" />
                            <label :for="'chkDeliveryCoin' + day">{{ day }}</label>
                        </div>
                    </div>
                </div>

                <div class="form-row" v-if="coinsDeliveryRequired">
                    <div id="lblWhoMakesUpCoin" class="form-label">Who makes up the coin delivered?</div>
                    <div class="form-input-number">
                        <select aria-labelledby="lblWhoProcessesCoin" class="form-input"
                            v-model="formData.coin_makes_up">
                            <option value="Loomis">Loomis</option>
                            <option value="Bank">Bank</option>
                        </select>
                    </div>
                </div>

                <div class="form-row">
                    <div id="lblFrequencyOfDelivery" class="form-label">Frequency of service</div>
                    <div class="form-input-number">
                        <select aria-labelledby="lblFrequencyOfDelivery" class="form-input"
                            v-model="formData.frequency_of_delivery">
                            <option value="Weekly">Weekly</option>
                            <option value="Fortnightly">Fortnightly</option>
                            <option value="Every 4 weeks">Every 4 weeks</option>
                            <option value="Monthly">Monthly</option>
                        </select>
                    </div>
                </div>

                <div class="form-row"
                    v-if="(notesDeliveryRequired && formData.notes_makes_up == 'Loomis') || (coinsDeliveryRequired && formData.coin_makes_up == 'Loomis')">
                    <div id="lblSiteCreditimit" class="form-label">What is the credit limit for this site:</div>
                    <div class="form-input-holder">
                        <input aria-labelledby="lblSiteCreditimit" type="text" class="form-input"
                            v-model="formData.site_credit_limit">
                    </div>
                </div>

                <template v-if="formData.coin_makes_up == 'Loomis'">
                    <div class="form-row">
                        <div id="lblInitialFloatDelivery" class="form-label">Do you require an initial float delivery?
                        </div>
                        <div class="form-input-number">
                            <select aria-labelledby="lblInitialFloatDelivery" class="form-input"
                                v-model="formData.initial_float_delivery">
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>

                    <template v-if="formData.initial_float_delivery == 'Yes'">
                        <div class="form-row">
                            <div class="form-label">Initial Float Date:</div>
                            <div class="form-input-number">
                                <flat-pickr v-model="formData.initial_float_date" ref="dateWrapperElement"
                                    :config="date_config" class="txt full date"></flat-pickr>
                            </div>
                        </div>
                        <div class="form-row">
                            <div id="lblNote50" class="form-label">£50:</div>
                            <div class="form-input-number">
                                <input aria-labelledby="lblNote50" type="number" class="form-input"
                                    v-model.number="formData.note_50">
                            </div>
                        </div>
                        <div class="form-row">
                            <div id="lblNote20" class="form-label">£20:</div>
                            <div class="form-input-number">
                                <input aria-labelledby="lblNote20" type="number" class="form-input"
                                    v-model.number="formData.note_20">
                            </div>
                        </div>
                        <div class="form-row">
                            <div id="lblNote10" class="form-label">£10:</div>
                            <div class="form-input-number">
                                <input aria-labelledby="lblNote10" type="number" class="form-input"
                                    v-model.number="formData.note_10">
                            </div>
                        </div>
                        <div class="form-row">
                            <div id="lblNote5" class="form-label">£5:</div>
                            <div class="form-input-number">
                                <input aria-labelledby="lblNote5" type="number" class="form-input"
                                    v-model.number="formData.note_5">
                            </div>
                        </div>
                        <div class="form-row">
                            <div id="lblCoin200" class="form-label">£2:</div>
                            <div class="form-input-number">
                                <input aria-labelledby="lblCoin200" type="number" class="form-input"
                                    v-model.number="formData.coin_200">
                            </div>
                        </div>
                        <div class="form-row">
                            <div id="lblCoin100" class="form-label">£1:</div>
                            <div class="form-input-number">
                                <input aria-labelledby="lblCoin100" type="number" class="form-input"
                                    v-model.number="formData.coin_100">
                            </div>
                        </div>
                        <div class="form-row">
                            <div id="lblCoin50" class="form-label">50p:</div>
                            <div class="form-input-number">
                                <input aria-labelledby="lblCoin50" type="number" class="form-input"
                                    v-model.number="formData.coin_50">
                            </div>
                        </div>
                        <div class="form-row">
                            <div id="lblCoin20" class="form-label">20p:</div>
                            <div class="form-input-number">
                                <input aria-labelledby="lblCoin20" type="number" class="form-input"
                                    v-model.number="formData.coin_20">
                            </div>
                        </div>
                        <div class="form-row">
                            <div id="lblCoin10" class="form-label">10p:</div>
                            <div class="form-input-number">
                                <input aria-labelledby="lblCoin10" type="number" class="form-input"
                                    v-model.number="formData.coin_10">
                            </div>
                        </div>
                        <div class="form-row">
                            <div id="lblCoin5" class="form-label">5p:</div>
                            <div class="form-input-number">
                                <input aria-labelledby="lblCoin5" type="number" class="form-input"
                                    v-model.number="formData.coin_5">
                            </div>
                        </div>
                        <div class="form-row">
                            <div id="lblCoin2" class="form-label">2p:</div>
                            <div class="form-input-number">
                                <input aria-labelledby="lblCoin2" type="number" class="form-input"
                                    v-model.number="formData.coin_2">
                            </div>
                        </div>
                        <div class="form-row">
                            <div id="lblCoin1" class="form-label">1p:</div>
                            <div class="form-input-number">
                                <input aria-labelledby="lblCoin1" type="number" class="form-input"
                                    v-model.number="formData.coin_1">
                            </div>
                        </div>
                    </template>
                </template>

            </template>

            <div class="form-row">
                <div id="lblOtherServicesDataAndFX" class="form-label">Additional Comments:</div>
                <div class="form-input-holder">
                    <textarea aria-labelledby="lblOtherServicesDataAndFX" placeholder="Comments on other services etc.."
                        class="form-input" v-model="formData.other_services_data_and_fx"></textarea>
                </div>
            </div>
        </div>

        <div v-if="pageNumber == 3">
            <h2>Bank Details</h2>

            <div class="form-row">
                <div id="lblBankingPartner" class="form-label">Who is your banking partner:</div>
                <div class="form-input-number">
                    <select aria-labelledby="lblBankingPartner" class="form-input" v-model="formData.banking_partner">
                        <option value="ALCS">ALCS</option>
                        <option value="BARCLAYS BANK">BARCLAYS BANK</option>
                        <option value="COOP BANK">COOP BANK</option>
                        <option value="HSBC">HSBC</option>
                        <option value="NATWEST">NATWEST</option>
                        <option value="LLOYDS TSB">LLOYDS TSB</option>
                    </select>
                </div>
            </div>

            <div class="form-row">
                <div id="lblSortCode" class="form-label">Sort Code:</div>
                <div class="form-input-holder">
                    <input aria-labelledby="lblSortCode" type="text" class="form-input" v-model="formData.sort_code">
                </div>
            </div>

            <div class="form-row">
                <div id="lblAccountNumber" class="form-label">Account Number:</div>
                <div class="form-input-holder">
                    <input aria-labelledby="lblAccountNumber" type="text" class="form-input"
                        v-model="formData.account_number">
                </div>
            </div>
        </div>

        <error-panel v-if="error_message" :content="error_message"></error-panel>

        <div class="reports-accounts-nav">
            <a href="" v-if="pageNumber > 1" class="prev" @click.prevent="prevPage">&lt; Prev</a>
            <a href="" v-if="pageNumber < 3" class="next" @click.prevent="nextPage">Next &gt;</a>
        </div>
    </div>
</template>

<script>
//7 days ahead
var minDate = new Date()
minDate.setDate(minDate.getDate() + 7)

export default {
    props: ['formData'],
    data() {
        return {
            pageNumber: 1,
            error_message: "",
            days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Ad Hoc", "Not Required"],

            date_config: {
                locale: {
                    firstDayOfWeek: 1,
                    weekdays: {
                        shorthand: ["S", "M", "T", "W", "T", "F", "S"]
                    }
                },
                minDate,
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "Y-m-d",
                disableMobile: "true"
            },
        };
    },
    created() {
        this.$emit('update:formData', { notes_collection_days: [], coin_collection_days: [], notes_delivery_days: [], coin_delivery_days: [] })
    },
    computed: {
        notesCollectionRequired() {
            return this.formData.collection_services == 'Yes' && this.formData.notes_collection_days && this.formData.notes_collection_days.length && !this.formData.notes_collection_days.includes('Not Required')
        },
        coinsCollectionRequired() {
            return this.formData.collection_services == 'Yes' && this.formData.coin_collection_days && this.formData.coin_collection_days.length && !this.formData.coin_collection_days.includes('Not Required')
        },
        notesDeliveryRequired() {
            return this.formData.delivery_services == 'Yes' && this.formData.notes_delivery_days && this.formData.notes_delivery_days.length && !this.formData.notes_delivery_days.includes('Not Required')
        },
        coinsDeliveryRequired() {
            return this.formData.delivery_services == 'Yes' && this.formData.coin_delivery_days && this.formData.coin_delivery_days.length && !this.formData.coin_delivery_days.includes('Not Required')
        },
    },
    methods: {
        handleCheckboxChange(day, event, prop) {
            if (day === "Not Required" && event.target.checked) {
                // Deselect all other values if "Not Required" is selected
                this.formData[prop] = ["Not Required"];
            } else if (day !== "Not Required" && event.target.checked) {
                // Remove "Not Required" if any other day is selected
                this.formData[prop] = this.formData[prop].filter(d => d !== "Not Required");
            }
        },
        prevPage() {
            this.error_message = ""
            this.pageNumber--
        },
        nextPage() {
            this.error_message = ""
            let formError = this.getFormError()

            if (formError) {
                this.error_message = formError
                return
            }

            this.pageNumber++
        },
        getFormError() {
            if (this.pageNumber == 1) {
                if (!this.formData.site_name) return "Please enter the name of the site"
                if (!this.formData.address_1) return "Please enter the first line of the address"
                if (!this.formData.address_2) return "Please enter the second line of the address"
                if (!this.formData.town) return "Please enter the town"
                if (!this.formData.county) return "Please enter the county"
                if (!this.formData.post_code) return "Please enter the post code"
                if (!this.formData.reference_number) return "Please enter the store number"
                if (!this.formData.site_contact_name) return "Please enter the site contact name"
                if (!this.formData.site_contact_number) return "Please enter the site contact number"
                if (!this.formData.site_contact_email) return "Please enter the site contact email"
                return ""
            }

            if (this.pageNumber == 2) {
                if (!this.formData.collection_services) return "Please select if you require collection services"
                if (this.formData.collection_services == 'Yes') {
                    if (this.formData.notes_collection_days.length == 0) return "Please select the days collection of notes is required"
                    if (this.notesCollectionRequired && !this.formData.notes_processor) return "Please select who processes the notes collected"
                    if (this.formData.coin_collection_days.length == 0) return "Please select the days collection of coin is required"
                    if (this.coinsCollectionRequired && !this.formData.coin_processor) return "Please select who processes the coin collected"
                    if (!this.formData.frequency_of_collection) return "Please select the frequency of collection"
                    if (!this.formData.service_start_date) return "Please select the service start date"
                }
                if (!this.formData.delivery_services) return "Please select if you require delivery services"
                if (this.formData.delivery_services == 'Yes') {
                    if (this.formData.notes_delivery_days.length == 0) return "Please select the days delivery of notes is required"
                    if (this.notesDeliveryRequired && !this.formData.notes_makes_up) return "Please select who makes up the notes delivered"
                    if (this.formData.coin_delivery_days.length == 0) return "Please select the days delivery of coin is required"
                    if (this.coinsDeliveryRequired && !this.formData.coin_makes_up) return "Please select who makes up the coin delivered"
                    if (!this.formData.frequency_of_delivery) return "Please select the frequency of delivery"
                    if ((this.notesDeliveryRequired && this.formData.notes_makes_up == 'Loomis' || this.coinsDeliveryRequired && this.formData.coin_makes_up == 'Loomis') && !this.formData.site_credit_limit) return "Please enter the credit limit for this site"
                    if (this.formData.coin_makes_up == 'Loomis') {
                        if (!this.formData.initial_float_delivery) return "Please select if you require an initial float delivery"
                        if (this.formData.initial_float_delivery == 'Yes') {
                            if (!this.formData.initial_float_date) return "Please select the initial float date"
                        }
                    }
                }
            }

            if (this.pageNumber == 3) {
                if (!this.formData.banking_partner) return "Please select your banking partner"
                if (!this.formData.sort_code) return "Please enter the sort code"
                if (!this.formData.account_number) return "Please enter the account number"
            }

            return ""
        }
    }
};
</script>

<style scoped>
.wrapper {
    border-radius: 0.5rem;
    padding: 3rem;
    background-color: #eee;
    margin-bottom: 2rem;
}

.checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 1rem;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5rem;
    margin-bottom: 2rem;
}

.form-input {
    width: 100%;
}

.form-input-number {
    width: 50%;
}

.form-label {
    padding: 1rem 0;
}

.form-input::placeholder {
    color: #ccc;
}

.form-textarea {
    height: 15rem;
}

.reports-accounts-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reports-accounts-nav .prev {
    margin-right: auto;
}

.reports-accounts-nav .next {
    margin-left: auto;
}

.reports-accounts-nav a {
    background: #dc002e;
    border-radius: 0.4rem;
    padding: 0.5rem;
    font-size: 1.2rem;
    color: #fff;
    text-decoration: none;
}

.reports-accounts-nav a:hover {
    background: #c4002a;
}

h3 {
    margin-bottom: 2rem;
    margin-top: 3rem;
}
</style>