<template>
    <div>
        <div class="form-row">
            <div class="form-label">Mulitple sites form:</div>
            <div class="form-input-holder">
                <button class="upload" @click="downloadFile">
                    Click to download
                    <img src="/images/icons/download.png" alt="Download" />
                </button>
            </div>

        </div>
        <div class="form-row">
            <div id="lblAdditionalComments" class="form-label">Additional Comments:</div>
            <div class="form-input-holder">
                <textarea aria-labelledby="lblAdditionalComments" placeholder="Comments on other services etc.."
                    class="form-input" v-model="formData.additonal_comments"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
//7 days ahead
var minDate = new Date()
minDate.setDate(minDate.getDate() + 7)

export default {
    props: ['formData'],
    data() {
        return {
            pageNumber: 1,
            error_message: "",
            days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Ad Hoc", "Not Required"],

            date_config: {
                locale: {
                    firstDayOfWeek: 1,
                    weekdays: {
                        shorthand: ["S", "M", "T", "W", "T", "F", "S"]
                    }
                },
                minDate,
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "Y-m-d",
                disableMobile: "true"
            },
        };
    },
    created() {
        this.$emit('update:formData', { notes_collection_days: [], coin_collection_days: [], notes_delivery_days: [], coin_delivery_days: [] })
    },
    computed: {
        notesCollectionRequired() {
            return this.formData.collection_services == 'Yes' && this.formData.notes_collection_days && this.formData.notes_collection_days.length && !this.formData.notes_collection_days.includes('Not Required')
        },
        coinsCollectionRequired() {
            return this.formData.collection_services == 'Yes' && this.formData.coin_collection_days && this.formData.coin_collection_days.length && !this.formData.coin_collection_days.includes('Not Required')
        },
        notesDeliveryRequired() {
            return this.formData.delivery_services == 'Yes' && this.formData.notes_delivery_days && this.formData.notes_delivery_days.length && !this.formData.notes_delivery_days.includes('Not Required')
        },
        coinsDeliveryRequired() {
            return this.formData.delivery_services == 'Yes' && this.formData.coin_delivery_days && this.formData.coin_delivery_days.length && !this.formData.coin_delivery_days.includes('Not Required')
        },
    },
    methods: {
        downloadFile() {
            const filePath = '/downloads/MULTIPLE NEW SITE FORM.xlsx'; // Path relative to the public folder
            const link = document.createElement('a'); // Create a temporary anchor element
            link.href = filePath; // Set the href to the file path
            link.download = 'MULTIPLE NEW SITE FORM.xlsx'; // Set the download attribute to suggest a file name
            link.click(); // Programmatically trigger a click on the anchor element
            link.remove(); // Clean up by removing the temporary element
        },
        prevPage() {
            this.error_message = ""
            this.pageNumber--
        },
        nextPage() {
            this.error_message = ""
            let formError = this.getFormError()

            if (formError) {
                this.error_message = formError
                return
            }

            this.pageNumber++
        },
        getFormError() {
            return ""
        }
    }
};
</script>

<style scoped>
.checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 1rem;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5rem;
    margin-bottom: 2rem;
}

.form-input {
    width: 100%;
}

.form-input-number {
    width: 50%;
}

.form-label {
    padding: 1rem 0;
}

.form-input::placeholder {
    color: #ccc;
}

.form-textarea {
    height: 15rem;
}

.reports-accounts-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reports-accounts-nav .prev {
    margin-right: auto;
}

.reports-accounts-nav .next {
    margin-left: auto;
}

.reports-accounts-nav a {
    background: #dc002e;
    border-radius: 0.4rem;
    padding: 0.5rem;
    font-size: 1.2rem;
    color: #fff;
    text-decoration: none;
}

.reports-accounts-nav a:hover {
    background: #c4002a;
}

h3 {
    margin-bottom: 2rem;
    margin-top: 3rem;
}

.upload {
    background: #ca0028;
    color: white;
    border-radius: 3rem;
    border: 0;
    padding: 1rem 2rem;
    font-size: 1.8rem;
    cursor: pointer;
    margin-bottom: 2rem;
}

.upload img {
    height: 1.8rem;
    position: relative;
    bottom: -0.2rem;
    margin-left: 0.5rem;
}
</style>