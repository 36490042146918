<template>
  <main class="content">
    <div class="inner content-grid">
      <div class="left">
        <page-heading heading="Contact Customer Services" icon="envelope-customer-services.png" :para="success_message
          ? 'Thank you, your request is now being actioned.'
          : 'Please enter the details using the form below.'
          "></page-heading>

        <template v-if="!success_message">
          <info-panel content="Fill out as much information as you can to help process your request."></info-panel>

          <div class="form">
            <AccountSelector v-if="$store.state.user_type == 'hq-user'" :accounts="accounts"
              v-bind:all_sites.sync="all_sites" v-bind:account_numbers.sync="account_numbers" />

            <div class="form-row" v-if="$store.state.user_type != 'hq-user'">
              <div class="form-label">Account number:</div>
              <div class="form-input-holder padded">{{ customer.account_number }}</div>
            </div>

            <div class="form-row">
              <div id="lblContactName" class="form-label">Contact name:</div>
              <div class="form-input-holder">
                <input aria-labelledby="lblContactName" type="text" class="form-input" v-model="contact_name">
              </div>
            </div>

            <div class="form-row">
              <div id="lblEmail" class="form-label">Email:</div>
              <div class="form-input-holder">
                <input aria-labelledby="lblEmail" type="text" class="form-input" v-model="email">
              </div>
            </div>

            <div class="form-row">
              <div id="lblTelephoneNumber" class="form-label">Telephone number:</div>
              <div class="form-input-holder">
                <input aria-labelledby="lblTelephoneNumber" type="text" class="form-input" v-model="telephone_number">
              </div>
            </div>

            <div class="form-row">
              <div id="lblDetailsOfIssue" class="form-label">Query type:</div>
              <div class="form-input-holder">
                <select aria-labelledby="lblDetailsOfIssue" class="form-input" v-model="issue_details">
                  <optgroup label="General">
                    <option value="Consumables">Consumables</option>
                    <option value="CashCoinOrders">Cash &amp; Coin Orders</option>
                    <option value="NewSites">New Sites</option>
                    <option value="Service-related issue">Service-related issue</option>
                    <option value="Reporting">Reporting</option>
                    <option value="Invoicing">Invoicing</option>
                    <option value="Discrepancies">Discrepancies</option>
                    <option value="Safepoint">Safepoint</option>
                    <option value="Driver behaviour">Driver behaviour</option>
                    <option value="Complaint">Complaint</option>
                  </optgroup>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            <template v-if="issue_details">
              <template v-if="issue_details == 'NewSites'">
                <div class="form-row">
                  <div class="form-label">&nbsp;</div>
                  <div class="form-input-holder">
                    <select aria-label="Query sub type" class="form-input" v-model="new_site_type">
                      <option value="AddNewSite">Add new site</option>
                      <option value="AddMultipleNewSites">Add multiple new sites</option>
                      <option value="NewSiteQuery">New site query</option>
                    </select>
                  </div>
                </div>
              </template>

              <div class="form-row">
                <div id="lblCaseNumber" class="form-label">If you already have a case number, please enter it
                  here
                </div>
                <div class="form-input-holder">
                  <input aria-labelledby="lblCaseNumber" type="text" class="form-input" v-model="formData.case_number">
                </div>
              </div>

              <CashCoinOrders ref="CashCoinOrders" v-if="issue_details == 'CashCoinOrders'" :formData="formData" />
              <Consumables ref="Consumables" v-else-if="issue_details == 'Consumables'" :formData="formData" />

              <template v-else-if="issue_details == 'NewSites'">
                <AddNewSite ref="AddNewSite" v-if="new_site_type == 'AddNewSite'" :formData="formData"
                  @update:formData="updateFormData" />
                <AddMultipleNewSites ref="AddMultipleNewSites" v-if="new_site_type == 'AddMultipleNewSites'"
                  :formData="formData" />
                <NewSiteQuery ref="NewSiteQuery" v-if="new_site_type == 'NewSiteQuery'" :formData="formData"
                  @update:formData="updateFormData" />
              </template>

              <template v-else>
                <div>
                  <div class="form-input-holder">
                    <textarea aria-label="Explain what has happened here" v-model="explanation"
                      class="form-input form-textarea" placeholder="Explain what has happened here..."></textarea>
                  </div>
                </div>
              </template>

              <template v-if="showFileSection">
                <div class="form-row">
                  <div class="form-label">File(s):</div>
                  <div class="form-input-holder">
                    <div class="note">
                      NOTE: Please ensure your files are either an image, or are one of the following supported types:
                      Excel
                      (.xls,
                      .xlsx), Word documents (.doc, .docx), Outlook messages (.msg), or PDF (.pdf).
                      <br />You can attach up to 3 files with a
                      limit of 10mb per file.
                    </div>
                    <div>
                      <input v-if="refresh" type="file"
                        accept="image/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-outlook,application/pdf"
                        @change="upload($event, 'file_1')" class="form-input form-file" id="file-input-1" />

                      <div class="uploaded" v-if="total_files >= 2">
                        {{ file_1_name }}
                        <a href @click.prevent="deleteFile(1)" class="delete">x</a>
                      </div>
                    </div>
                    <div>
                      <input v-if="refresh" type="file"
                        accept="image/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-outlook,application/pdf"
                        @change="upload($event, 'file_2')" class="form-input form-file" id="file-input-2" />
                      <div class="uploaded" v-if="total_files >= 3">
                        {{ file_2_name }}
                        <a href @click.prevent="deleteFile(2)" class="delete">x</a>
                      </div>
                    </div>
                    <div>
                      <input v-if="refresh" type="file"
                        accept="image/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-outlook,application/pdf"
                        @change="upload($event, 'file_3')" class="form-input form-file" id="file-input-3" />
                      <div class="uploaded" v-if="total_files >= 4">
                        {{ file_3_name }}
                        <a href @click.prevent="deleteFile(3)" class="delete">x</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div></div>
                  <div class="form-input-holder">
                    <button v-if="total_files < 4" class="upload" @click="triggerUploader()">
                      {{ total_files > 1 ? "Add another" : "Click to attach" }}
                      <img src="/images/icons/upload.png" alt="Upload" />
                    </button>
                  </div>
                </div>

              </template>

              <div class="checkbox-container" v-if="issue_details == 'NewSites' && new_site_type == 'AddNewSite'">
                <input id="chkNotice" class="chk" type="checkbox" v-model="notice" />
                <label class="checkbox-label" for="chkNotice">If Loomis is processing any part
                  of the services
                  you have
                  requested,
                  please ensure
                  you contact your relationship manager at the bank to expedite the setup process.</label>
              </div>
            </template>
          </div>

          <error-panel v-if="error_message" :content="error_message"></error-panel>

          <div class="actions">
            <button :disabled="submit_loading" @click="submit">
              <template v-if="submit_loading">
                <font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" />
              </template>
              <template v-else> Submit <img src="/images/icons/button-icon-submit.png" alt="submit" /> </template>
            </button>
          </div>
        </template>
        <template v-else>
          <success-panel :content="success_message"></success-panel>
          <p class="back"><router-link to="/home">&lt; Back to Dashboard...</router-link></p>
        </template>
      </div>
      <right-column></right-column>
    </div>
  </main>
</template>

<script>
import api from "@/services/api"
import CashCoinOrders from '../components/CustomerServices/CashCoinOrders.vue';
import Consumables from '../components/CustomerServices/Consumables.vue';
import AddNewSite from '../components/CustomerServices/AddNewSite.vue';
import AddMultipleNewSites from '../components/CustomerServices/AddMultipleNewSites.vue';
import NewSiteQuery from '../components/CustomerServices/NewSiteQuery.vue';
import { formatAccountNumber, reverseFormatAccountNumber } from "@/util/vars"

export default {
  components: {
    CashCoinOrders,
    Consumables,
    AddNewSite,
    AddMultipleNewSites,
    NewSiteQuery,
  },
  data() {
    return {
      formData: {},

      all_sites: false,
      account_numbers: [],
      account_number: "",
      contact_name: "",
      email: "",
      telephone_number: "",
      issue_details: "",
      new_site_type: "",
      explanation: "",
      notice: false,

      refresh: true,
      total_files: 1,
      file_1_data: "",
      file_2_data: "",
      file_3_data: "",
      file_1_name: "",
      file_2_name: "",
      file_3_name: "",

      submit_loading: false,

      error_message: "",
      success_message: "",

      consumables: [],
      accounts: [],
    }
  },
  created() {
    var contact_name_prop = this.$store.state.myDetails.filter(p => p.property == "name")
    if (contact_name_prop.length) this.contact_name = contact_name_prop[0]._content

    var email_prop = this.$store.state.myDetails.filter(p => p.property == "email")
    if (email_prop.length) this.email = email_prop[0]._content

    var telephone_number_prop = this.$store.state.myDetails.filter(p => p.property == "phone_number")
    if (!telephone_number_prop.length) telephone_number_prop = this.$store.state.myDetails.filter(p => p.property == "custom:phone")
    if (telephone_number_prop.length) this.telephone_number = telephone_number_prop[0]._content

    api
      .request("get", "consumable?type=all")
      .then(res => {
        this.consumables = res.consumables
      })

    api.request("get", "account?all=true").then(res => {
      res.forEach(a => (a.formattedAccountNumber = this.formatAccNo(a.account_number)))

      this.accounts = res
    })
  },
  computed: {
    showFileSection() {
      //if (this.issue_details === "Consumables") {
      //if (this.request_type === "Query") return true
      //return false
      //}

      //if (this.issue_details === "CashCoinOrders") {
      // if (this.request_type === "Query") return true
      //return false
      //}

      return true
    },
    hq_user() {
      return this.$store.state.hq_user
    },
    customer() {
      return this.$store.state.customer
    },
  },
  methods: {
    updateFormData(data) {
      this.formData = data
    },
    triggerUploader() {
      document.getElementById("file-input-" + this.total_files).click()
    },
    deleteFile(i) {
      this.refresh = false
      this.$nextTick().then(() => (this.refresh = true))

      this.total_files--

      if (i == 1) {
        this["file_1_data"] = this["file_2_data"]
        this["file_1_name"] = this["file_2_name"]
      }
      if (i <= 2) {
        this["file_2_data"] = this["file_3_data"]
        this["file_2_name"] = this["file_3_name"]
      }
      this["file_3_data"] = ""
      this["file_3_name"] = ""
    },
    upload: function (e, prop) {
      this.success_message = ""
      this.error_message = ""

      var files = e.target.files || e.dataTransfer.files

      if (!files.length) return
      if (files[0].size > 10485760) return (this.error_message = "Please ensure all files are under 10mb")

      var reader = new FileReader()
      reader.addEventListener(
        "load",
        () => {
          const supportedFileTypes = [
            "data:image/",
            "data:application/vnd.ms-excel",
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "data:application/msword",
            "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "data:application/vnd.ms-outlook",
            "data:application/pdf"
          ];

          if (!supportedFileTypes.some(type => reader.result.startsWith(type))) {
            return (this.error_message = "Please select a valid file. Supported types: JPEG images, Excel (.xls, .xlsx), Word documents (.doc, .docx), Outlook messages (.msg), or PDF (.pdf).");
          }

          this[prop + "_data"] = reader.result
          this[prop + "_name"] = files[0].name
          this.total_files++
        },
        false
      )

      reader.readAsDataURL(files[0])
    },
    reverseFormatAccNo(acc_no) {
      return reverseFormatAccountNumber(acc_no)
    },
    formatAccNo(acc_no) {
      return formatAccountNumber(acc_no)
    },
    submit() {
      this.submit_loading = true
      this.error_message = ""
      this.success_message = ""

      if (this.issue_details == "NewSites" && (this.new_site_type == "AddNewSite" || this.new_site_type == "AddMultipleNewSites")) this.all_sites = true

      if (this.$store.state.user_type == 'hq-user' && !this.all_sites && !this.account_numbers.length) {
        this.error_message = "Please select account(s)"
        this.submit_loading = false
        return
      }
      if (!this.contact_name) {
        this.error_message = "Please enter a contact name"
        this.submit_loading = false
        return
      }
      if (!this.email) {
        this.error_message = "Please enter your email address"
        this.submit_loading = false
        return
      }
      if (!this.telephone_number) {
        this.error_message = "Please enter your telephone number"
        this.submit_loading = false
        return
      }
      if (!this.issue_details) {
        this.error_message = "Please enter the details of the fault"
        this.submit_loading = false
        return
      }

      if (this.issue_details == "CashCoinOrders" || this.issue_details == "Consumables" || this.issue_details == "NewSites") {
        let component = this.issue_details == "NewSites" ? this.$refs[this.new_site_type] : this.$refs[this.issue_details]
        let formError = component.getFormError()

        if (formError) {
          this.error_message = formError
          this.submit_loading = false
          return
        }
      } else {
        if (!this.explanation) {
          this.error_message = "Please explain what has happened here"
          this.submit_loading = false
          return
        }
      }

      if (this.issue_details == "NewSites" && this.new_site_type == "AddMultipleNewSites" && this.total_files < 2) {
        this.error_message = "Please upload the completed multiple new sites form"
        this.submit_loading = false
        return
      }

      if (this.issue_details == "NewSites" && this.new_site_type == "AddNewSite" && !this.notice) {
        this.error_message = "Please tick to confirm you are aware you may need to contact your relationship manager at the bank to expedite the setup process"
        this.submit_loading = false
        return
      }

      var data = {
        contact_name: this.contact_name,
        email: this.email,
        telephone_number: this.telephone_number,
        issue_details: this.issue_details,
        new_site_type: this.new_site_type,

        ...this.formData,

        explanation: this.explanation,

        file_1_data: this.file_1_data,
        file_2_data: this.file_2_data,
        file_3_data: this.file_3_data,
        file_1_name: this.file_1_name,
        file_2_name: this.file_2_name,
        file_3_name: this.file_3_name,
      }

      if (this.$store.state.user_type == 'hq-user' && !this.all_sites) {
        const accountData = []
        this.account_numbers.forEach(an => {
          const account = this.accounts.find(a => a.account_number === an)
          accountData.push({
            "Site Number": this.reverseFormatAccNo(account.account_number),
            "Site Name": account.company
          })
        })

        data.account_numbers = accountData
      }

      if (this.$store.state.user_type == 'hq-user') {
        data.accountNumber = this.hq_user.prefix
        data.accountName = this.hq_user.name
      } else {
        data.accountNumber = this.customer.account_number
        data.accountName = this.customer.company
      }

      api
        .request("post", "complaints", data)
        .then(() => {
          this.success_message = "Your request has successfully been submitted."
        })
        .catch(() => {
          this.error_message = "There was an error, please try again"
        })
        .finally(() => {
          this.submit_loading = false
        })
    }
  },
  watch: {
    issue_details() {
      this.formData = {}
      this.request_type = ""
      this.query_details = ""
    }
  },
}
</script>

<style scoped>
.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

ul {
  list-style: none;
  padding-left: 0;
}

li {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

li:hover {
  border: 1px solid #29383f;
}

.selected-account {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-icon {
  margin-left: auto;
  padding: 0.5rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 5rem;
  margin-bottom: 2rem;
}

.form-input {
  width: 100%;
}

.form-input-number {
  width: 50%;
}

.form-label {
  padding: 1rem 0;
}

.checkbox-label {
  padding: 1rem 1rem;
}

.form-input::placeholder {
  color: #ccc;
}

.form-textarea {
  height: 15rem;
}

.radio {
  display: inline-block;
  margin: 1.5rem 0;
  margin-right: 3rem;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 2.8rem;
  cursor: pointer;
  line-height: 2rem;
  display: inline-block;
  font-size: 2rem;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  background: #ca0028;
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

[type="radio"]:focus-visible+label:before {
  outline: 2px solid #CB4E0B !important;
  outline-offset: 2px;
}

.note {
  font-size: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  opacity: 0.7;
}

.btn-another,
.upload {
  background: #ca0028;
  color: white;
  border-radius: 3rem;
  border: 0;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  cursor: pointer;
  margin-bottom: 2rem;
}

.upload img {
  height: 1.8rem;
  position: relative;
  bottom: -0.2rem;
  margin-left: 0.5rem;
}

.form-file {
  display: none;
}

.form-right {
  text-align: right;
}

.delete {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background: #5f6062;
  color: white;
  text-align: center;
  line-height: 1.5rem;
  position: relative;
  top: -0.25rem;
  margin-left: 1rem;
}

.delete:hover {
  background: #ca0028;
}

.uploaded {
  margin: 0.5rem 0;
}



@media screen and (max-width: 600px) {
  .actions button {
    font-size: 1.8rem;
  }

  .actions button img {
    height: 1.5rem;
    margin-bottom: 0;
  }

  .form-row {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
</style>
