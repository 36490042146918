<template>
    <div>
        <!-- <div class="form-row">
    <div id="lblTypeOfRequest" class="form-label">Type of request:</div>
    <div class="form-input-holder">
      <select aria-labelledby="lblTypeOfRequest" class="form-input" v-model="formData.request_type">
        <option value="Order">Order</option>
        <option value="Query">Query</option>
      </select>
    </div>
  </div> -->

        <!-- <div v-if="formData.request_type == 'Query'"> -->
        <div class="form-input-holder">
            <textarea aria-label="Details of query" v-model="formData.query_details" class="form-input form-textarea"
                placeholder="Details of query..."></textarea>
        </div>
        <!-- </div>

  <template v-if="formData.request_type == 'Order'">
    <div class="form-row">
      <div class="form-label">Delivery date:</div>
      <div class="form-input-number">
        <flat-pickr v-model="formData.consumable_delivery_date" ref="dateWrapperElement" :config="date_config"
          class="txt full date"></flat-pickr>
      </div>
    </div>
    <div class="form-row">
      <div id="lblBagType" class="form-label">Bag type:</div>
      <div class="form-input-holder">
        <select aria-labelledby="lblBagType" class="form-input" v-model="formData.bag_type">
          <option v-for="consumable in consumables" :value="consumable.name">{{ consumable.name }}</option>
        </select>
      </div>
    </div>
  </template> -->
    </div>
</template>

<script>
export default {
    props: ['formData'],
    methods: {
        getFormError() {
            // if (!this.request_type) {
            //   this.error_message = "Please select a type of request"
            //   this.submit_loading = false
            //   return
            // }

            // if (this.request_type == "Query" && !this.query_details) {
            if (!this.formData.query_details) return "Please enter the details of the query"


            // if (this.request_type == "Order" && !this.consumable_delivery_date) {
            //   this.error_message = "Please select a delivery date"
            //   this.submit_loading = false
            //   return
            // }
            // if (this.request_type == "Order" && !this.bag_type) {
            //   this.error_message = "Please select the bag type"
            //   this.submit_loading = false
            //   return
            // }

            return ""
        }
    }
};
</script>

<style scoped>
.form-row {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5rem;
    margin-bottom: 2rem;
}

.form-input {
    width: 100%;
}

.form-input-number {
    width: 50%;
}

.form-label {
    padding: 1rem 0;
}

.form-input::placeholder {
    color: #ccc;
}

.form-textarea {
    height: 15rem;
}
</style>