<template>
  <div>
    <div class="form-input-holder">
      <textarea aria-label="Details of query" v-model="formData.query_details" class="form-input form-textarea"
        placeholder="Details of query..."></textarea>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData'],
  methods: {
    getFormError() {
      if (!this.formData.query_details) return "Please enter the details of the query"

      return ""
    }
  }
};
</script>

<style scoped>
.form-row {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 5rem;
  margin-bottom: 2rem;
}

.form-input {
  width: 100%;
}

.form-input::placeholder {
  color: #ccc;
}

.form-textarea {
  height: 15rem;
}
</style>