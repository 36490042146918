<template>
    <div>
        <!-- <div class="form-row">
    <div id="lblTypeOfRequest" class="form-label">Type of request:</div>
    <div class="form-input-holder">
      <select aria-labelledby="lblTypeOfRequest" class="form-input" v-model="formData.request_type">
        <option value="Order">Order</option>
        <option value="Query">Query</option>
      </select>
    </div>
  </div> -->

        <!-- <div v-if="formData.request_type == 'Query'"> -->
        <div>
            <div class="form-input-holder">
                <textarea aria-label="Details of query" v-model="formData.query_details"
                    class="form-input form-textarea" placeholder="Details of query..."></textarea>
            </div>
        </div>

        <template v-if="formData.request_type == 'Order'">
            <div class="form-row">
                <div class="form-label">Delivery date:</div>
                <div class="form-input-number">
                    <flat-pickr v-model="formData.order_delivery_date" ref="dateWrapperElement" :config="date_config"
                        class="txt full date"></flat-pickr>
                </div>
            </div>
            <div class="form-row">
                <div id="lblOrderType" class="form-label">Order type:</div>
                <div class="form-input-number">
                    <select aria-labelledby="lblOrderType" class="form-input" v-model="formData.order_type">
                        <option value="standard">Standard</option>
                        <option value="initial_float">Initial Float</option>
                    </select>
                </div>
            </div>
            <div class="form-row">
                <div id="lblNote50" class="form-label">£50:</div>
                <div class="form-input-number">
                    <input aria-labelledby="lblNote50" type="number" class="form-input"
                        v-model.number="formData.note_50">
                </div>
            </div>
            <div class="form-row">
                <div id="lblNote20" class="form-label">£20:</div>
                <div class="form-input-number">
                    <input aria-labelledby="lblNote20" type="number" class="form-input"
                        v-model.number="formData.note_20">
                </div>
            </div>
            <div class="form-row">
                <div id="lblNote10" class="form-label">£10:</div>
                <div class="form-input-number">
                    <input aria-labelledby="lblNote10" type="number" class="form-input"
                        v-model.number="formData.note_10">
                </div>
            </div>
            <div class="form-row">
                <div id="lblNote5" class="form-label">£5:</div>
                <div class="form-input-number">
                    <input aria-labelledby="lblNote5" type="number" class="form-input" v-model.number="formData.note_5">
                </div>
            </div>
            <div class="form-row">
                <div id="lblCoin200" class="form-label">£2:</div>
                <div class="form-input-number">
                    <input aria-labelledby="lblCoin200" type="number" class="form-input"
                        v-model.number="formData.coin_200">
                </div>
            </div>
            <div class="form-row">
                <div id="lblCoin100" class="form-label">£1:</div>
                <div class="form-input-number">
                    <input aria-labelledby="lblCoin100" type="number" class="form-input"
                        v-model.number="formData.coin_100">
                </div>
            </div>
            <div class="form-row">
                <div id="lblCoin50" class="form-label">50p:</div>
                <div class="form-input-number">
                    <input aria-labelledby="lblCoin50" type="number" class="form-input"
                        v-model.number="formData.coin_50">
                </div>
            </div>
            <div class="form-row">
                <div id="lblCoin20" class="form-label">20p:</div>
                <div class="form-input-number">
                    <input aria-labelledby="lblCoin20" type="number" class="form-input"
                        v-model.number="formData.coin_20">
                </div>
            </div>
            <div class="form-row">
                <div id="lblCoin10" class="form-label">10p:</div>
                <div class="form-input-number">
                    <input aria-labelledby="lblCoin10" type="number" class="form-input"
                        v-model.number="formData.coin_10">
                </div>
            </div>
            <div class="form-row">
                <div id="lblCoin5" class="form-label">5p:</div>
                <div class="form-input-number">
                    <input aria-labelledby="lblCoin5" type="number" class="form-input" v-model.number="formData.coin_5">
                </div>
            </div>
            <div class="form-row">
                <div id="lblCoin2" class="form-label">2p:</div>
                <div class="form-input-number">
                    <input aria-labelledby="lblCoin2" type="number" class="form-input" v-model.number="formData.coin_2">
                </div>
            </div>
            <div class="form-row">
                <div id="lblCoin1" class="form-label">1p:</div>
                <div class="form-input-number">
                    <input aria-labelledby="lblCoin1" type="number" class="form-input" v-model.number="formData.coin_1">
                </div>
            </div>
            <div class="form-row">
                <div id="lblStandingOrder" class="form-label">Can we help by setting this up as a standing order?
                </div>
                <div class="form-input-number">
                    <select aria-labelledby="lblStandingOrder" class="form-input" v-model="formData.standing_order">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <template v-if="formData.standing_order == 'Yes'">
                <div class="form-row">
                    <div id="lblStandingOrderFrequency" class="form-label">Frequency:</div>
                    <div class="form-input-number">
                        <select aria-labelledby="lblStandingOrderFrequency" class="form-input"
                            v-model="formData.standing_order_frequency">
                            <option value="Weekly">Weekly</option>
                            <option value="Fortnightly">Fortnightly</option>
                            <option value="Monthly">Monthly</option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-label">End date:</div>
                    <div class="form-input-number">
                        <flat-pickr v-model="formData.standing_order_end_date" ref="dateWrapperElement"
                            :config="date_config" class="txt full date"></flat-pickr>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
var minDate = new Date()

export default {
    props: ['formData'],
    data() {
        return {
            date_config: {
                locale: {
                    firstDayOfWeek: 1,
                    weekdays: {
                        shorthand: ["S", "M", "T", "W", "T", "F", "S"]
                    }
                },
                minDate,
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "Y-m-d",
                disableMobile: "true"
            },
        };
    },
    methods: {
        getFormError() {
            // if (!this.formData.request_type) {
            //   return "Please select the request type"      
            // }

            // if (this.formData.request_type == "Query" && !this.formData.query_details) {
            if (!this.formData.query_details) return "Please enter the details of the query"

            if (this.formData.request_type == "Order") {
                if (!this.formData.order_delivery_date) return "Please select a delivery date"
                if (!this.formData.order_type) return "Please select the order type"

                if (this.formData.note_50) total += this.formData.note_50
                if (this.formData.note_20) total += this.formData.note_20
                if (this.formData.note_10) total += this.formData.note_10
                if (this.formData.note_5) total += this.formData.note_5
                if (this.formData.coin_200) total += this.formData.coin_200
                if (this.formData.coin_100) total += this.formData.coin_100
                if (this.formData.coin_50) total += this.formData.coin_50
                if (this.formData.coin_20) total += this.formData.coin_20
                if (this.formData.coin_10) total += this.formData.coin_10
                if (this.formData.coin_5) total += this.formData.coin_5
                if (this.formData.coin_2) total += this.formData.coin_2
                if (this.formData.coin_1) total += this.formData.coin_1
                this.formData.total = total

                if (!total) return "Please enter the amount of cash and coin you would like to order"

                if (!this.formData.standing_order) return "Please select if you would like to set up a standing order"
                if (this.formData.standing_order == "Yes" && !this.formData.standing_order_frequency) return "Please select a frequency for the standing order"
                if (this.formData.standing_order == "Yes" && !this.formData.standing_order_end_date) return "Please select an end date for the standing order"
            }

            return ""
        }
    }
};
</script>

<style scoped>
.form-row {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5rem;
    margin-bottom: 2rem;
}

.form-input {
    width: 100%;
}

.form-input-number {
    width: 50%;
}

.form-label {
    padding: 1rem 0;
}

.form-input::placeholder {
    color: #ccc;
}

.form-textarea {
    height: 15rem;
}
</style>